import _ from 'lodash'

export class RemoteGlobalsInterface {
  constructor(modelJson) {
    this._globals = modelJson || {
      navigation: {},
      sessionInfo: {},
      appsData: {},
      siteMemberData: {uid: null, permissions: null, onUserLogin: []},
      multilingualInfo: {isEnabled: false, currentLanguageCode: null, siteLanguages: [], onCurrentLanguageChange: []},
      appStudioWidgetData: {isApplicationStudio: false, onPropsChanged: []},
      deviceType: null,
      lightboxContext: {},
      contextId: null
    }
  }

  addNavigation(navigationData) {
    this._globals.navigation = navigationData
  }

  addSiteMemberData(siteMemberData) {
    const shouldTriggerOnLoginCallback = siteMemberData.uid && siteMemberData.uid !== this._globals.siteMemberData.uid
    _.assign(this._globals.siteMemberData, siteMemberData)
    if (shouldTriggerOnLoginCallback) {
      const onUserLoginCallbacks = this._globals.siteMemberData.onUserLogin
      _.forEach(onUserLoginCallbacks, callback => {
        callback(siteMemberData)
      })
    }
  }

  addAppsData(appsData) {
    this._globals.appsData = appsData
  }

  addOnUserLoginCallback(callback) {
    this._globals.siteMemberData.onUserLogin.push(callback)
  }

  addSessionInfoProp(addSessionInfoProp) {
    _.assign(this._globals.sessionInfo, addSessionInfoProp)
  }

  addDeviceType(deviceType) {
    this._globals.deviceType = deviceType
  }

  addLightboxContext(lightboxContext) {
    this._globals.lightboxContext = lightboxContext
  }

  addContextId(contextId) {
    this._globals.contextId = contextId
  }

  addMultilingualInfo(multilingualInfo) {
    const shouldTriggerOnCurrentLanguageChange = multilingualInfo.currentLanguageCode && 
      multilingualInfo.currentLanguageCode !== this._globals.multilingualInfo.currentLanguageCode

    _.assign(this._globals.multilingualInfo, multilingualInfo)
    if (shouldTriggerOnCurrentLanguageChange) {
      const onCurrentLanguageChangeCallbacks = this._globals.multilingualInfo.onCurrentLanguageChange
      _.forEach(onCurrentLanguageChangeCallbacks, callback => {
        callback(multilingualInfo)
      })
    }
  }

  addOnCurrentLanguageChangeCallback(callback) {
    this._globals.multilingualInfo.onCurrentLanguageChange.push(callback)
  }

  //Getters

  getAppsData() {
    return _.cloneDeep(this._globals.appsData)
  }

  getDeviceType() {
    return this._globals.deviceType
  }

  getLightboxContext() {
    return _.cloneDeep(this._globals.lightboxContext)
  }

  getContextId() {
    return this._globals.contextId
  }

  //Getters

  getAppData(appDefinitionId) {
    return _.cloneDeep(_.get(this._globals.appsData, appDefinitionId))
  }

  getNavigationInfo() {
    return _.cloneDeep(this._globals.navigation)
  }

  getSiteMemberData(appDefinitionId) {
    const siteMemberData = _.cloneDeep(this._globals.siteMemberData)
    if (appDefinitionId) {
      const appData = _.get(this._globals.appsData, appDefinitionId)
      if (appData) {
        _.assign(siteMemberData, _.pick(appData, ['instance', 'instanceId']))
      }
    }
    return siteMemberData
  }

  getMultilingualInfo() {
    return _.cloneDeep(this._globals.multilingualInfo)
  }

  getSessionInfo() {
    return _.cloneDeep(this._globals.sessionInfo)
  }

  addIsApplicationStudio(isApplicationStudio) {
    this._globals.appStudioWidgetData.isApplicationStudio = isApplicationStudio
  }

  getIsApplicationStudio() {
    return this._globals.appStudioWidgetData.isApplicationStudio
  }

  addOnWidgetPropsChangedCallback(callback) {
    this._globals.appStudioWidgetData.onPropsChanged.push(callback)
  }

  triggerWidgetPropsChanged(oldProps, newProps) {
    _.forEach(this._globals.appStudioWidgetData.onPropsChanged, callback => {
      callback(_.cloneDeep(oldProps), _.cloneDeep(newProps))
    })
  }

  toJson() {
    return this._globals
  }
}
